import { createApp } from "vue";
import { createPinia } from "pinia";

// Components
import App from "./App.vue";
import router from "@/router";
import _ from "lodash";
import VueCookies from "vue3-cookies";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import VueSweetalert2 from "vue-sweetalert2";
import * as Sentry from "@sentry/vue";
import "sweetalert2/dist/sweetalert2.min.css";

//import './assets/main.css';
//axios golbal config
// axios.defaults.baseURL = import.meta.env.API_URL;
// axios.defaults.headers.post["Content-Type"] = "application/json";

import vuetify from "./plugins/vuetify";
import spinner from "@/components/helperComponents/spinner.vue";
import loader from "@/components/helperComponents/loader.vue";

// axios global config

const app = createApp(App);
const pinia = createPinia();

//Sentry Config

Sentry.init({
  app,
  dsn: "https://488fa1b878c9e2487defbad0525cf455@o4506740210663424.ingest.sentry.io/4506740856979456",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration(),
    Sentry.vueIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/dev.insur-link\.com\//,
    /^https:\/\/app.insur-link\.com\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.component("loader", loader);
app.component("spinner", spinner);

app.config.performance = true;

app.use(router);
app.use(pinia);
app.use(_);
app.use(vuetify);
app.use(VueCookies);
app.use(PerfectScrollbar);
app.use(VueSweetalert2);
app.mount("#app");
