import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import { useCookies } from "vue3-cookies";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: { title: "Login Page" },
  },
  {
    path: "/redirect",
    component: () => import("@/Layouts/BlankLayout.vue"),
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/Error404View.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("@/views/Error404View.vue"),
  },
  {
    path: "/",
    name: "app",
    component: () => import("@/Layouts/LoggedInLayout.vue"),
    redirect: "/dashboard",
    meta: { title: "Insur-Link Dashboard" },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/advancedsearch",
        name: "advancedsearch",
        component: () => import("@/views/AdvancedClaimSearchView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/createneweclaim",
        name: "createneweclaim",
        component: () =>
          import("@/components/createClaimComponents/newClaimComponent.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/createclaim",
        name: "createclaim",
        component: () => import("@/views/CreateClaimView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/eclaimsbatches",
        name: "eclaimsbatches",
        component: () => import("@/views/ClaimsBatchesView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/eclaimsbatchdetails",
        name: "eclaimsbatchdetails",
        component: () => import("@/views/BatchDetailsView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/eclaimshistory",
        name: "eclaimshistory",
        component: () => import("@/views/ClaimsHistoryView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/accountsettings",
        name: "accountsettings",
        component: () => import("@/views/ProfileSettingsView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/claimsummary",
        name: "claimsummary",
        component: () => import("@/views/ClaimSummaryView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const whiteList = ["/login"]; // no redirect whitelist

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isJwtToken = useCookies().cookies.isKey("jwtToken");
  if (isJwtToken) {
    if (to.name === "login") {
      next({ name: "app" });
    } else {
      next();
      return;
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
