<script setup lang="ts">
import { RouterView } from "vue-router";
import mobileDeviceComponent from "@/components/helperComponents/mobileDeviceComponent.vue";
import { useDisplay } from "vuetify";

const { smAndDown } = useDisplay();
</script>

<template>
  <v-app>
    <v-main>
      <mobileDeviceComponent v-if="smAndDown" />
      <RouterView v-else />
    </v-main>
  </v-app>
</template>

<style lang="css"></style>
